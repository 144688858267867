import React, { useEffect, useState, Suspense } from "react";
import { NavLink } from "react-router-dom";

import GlobalMetaTags from "../components/globalMetaTags";
import Loader from "../components/loader";

import setBodyColor from "../js/setBodyColor";

import BottomMenu from "../components/MenuBottom";

import NewsletterModal from "../components/NewsletterModal.js";

const Home = () => {
  // newsletter
  const [isModalOpen, setModalOpen] = useState(true);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  useEffect(() => {
    const preloadComponent = async () => {
      await import("./eshop");
    };
    preloadComponent();
  }, []);

  setBodyColor({ color: "#222222" });
  //setFontColor({ color: "#222222" });

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const onPageLoad = () => {
      const loader = document.querySelector(".loader");
      loader.className += " hidden";
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      return () => window.removeEventListener("load", onPageLoad);
    }

    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <main>
      {/*   <Suspense fallback={}>
        <EshopPage />
      </Suspense> */}
      <NewsletterModal isOpen={isModalOpen} onClose={closeModal} />
      <GlobalMetaTags pageTitle={"XIMONLEE"} />
      <Loader />

      {/*      <div className="loader">
        <div className="textloader flicker-loader">loading</div>
      </div>
 */}
      {/*  <button onClick={openModal}>Sign Up for Newsletter</button> */}

      <BottomMenu />

      <NavLink className="link" to="/current">
        <video
          className="videoindex"
          autoPlay
          loop
          muted
          playsInline
          alt="Ximon Lee SS24 presentation"
        >
          <source
            src={"../content/videos/XIMONLEE_SS25_video.mp4"}
            type="video/mp4"
          />
        </video>

        {/*   <div>
          {width > 778 ? (
            <video
              className="videoindex"
              autoPlay
              loop
              muted
              playsInline
              alt="Ximon Lee AW23 runway"
              width="1536"
              height="864"
            >
              <source
                src={"../content/videos/Ximon_Lee_AW23_runway.mp4"}
                type="video/mp4"
              />
            </video>
          ) : (
            <video
              className="videoindex"
              autoPlay
              loop
              muted
              playsInline
              alt="Ximon Lee AW23 runway"
              width="600"
              height="338"
            >
              <source
                src={"../content/videos/Ximon_Lee_AW23_runway_mobile.mp4"}
                type="video/mp4"
              />
            </video>
          )}
        </div> */}
      </NavLink>
    </main>
  );
};

export default Home;
