import React from "react";

import ss25CollectionImages from "../js/database/database-ss25-collection";

import setBodyColor from "../js/setBodyColor";

import { SwiperSlide } from "swiper/react";

import MainPagesContainer from "../components/containers/MainPagesContainer";

const alt = "SS25 collection";
const imgWidth = 833; // IMPORTANT TO CHANGE TO RIGHT SIZE, OTHERWISE SHIFT IN THE SLIDES
const imgHeight = 1250;

const Current = (props) => {
  setBodyColor({ color: "#222222" });
  // setFontColor({ color: "#5e5e5e" });

  const styles = {
    color: "#dddddd",
  };

  const handlePdfDownload = () => {
    const pdfUrl = "../../content/pdf/XIMONLEE_SS25_SHOW_LOOKS.pdf";
    const anchor = document.createElement("a");
    anchor.href = pdfUrl;
    anchor.download = "XIMONLEE_SS25_SHOW_LOOKS.pdf";
    anchor.click();
    console.log("clicked");
  };

  return (
    <MainPagesContainer
      pageTitle={alt}
      //title="Video"
      //pdf="pdf"
      //src={"../content/videos/XIMONLEE_SS25_video.mp4"}
      style={styles.color}
      shouldHide={true}
      PDFshouldHide={false}
      handlePdfDownload={handlePdfDownload}
    >
      {React.Children.toArray(
        ss25CollectionImages.map(({ srcJpeg, srcWebp, loading }) => (
          <SwiperSlide>
            <picture>
              <source srcSet={srcWebp} type="image/webp" />
              <img
                src={srcJpeg}
                width={imgWidth}
                height={imgHeight}
                alt={alt}
                loading="eager"
              />
            </picture>
          </SwiperSlide>
        ))
      )}
    </MainPagesContainer>
  );
};

export default Current;
