const ss25CollectionImages = [
    {
        key: 1,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK1.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK1.webp",
        loading: "eager"
    },
    {
        key: 2,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK2.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK2.webp",
        loading: "eager"
    },
    {
        key: 3,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK3.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK3.webp",
        loading: "eager"
    },

    {
        key: 4,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK4.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK4.webp",
        loading: "lazy"
    },
    {
        key: 5,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK5.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK5.webp",
        loading: "lazy"
    },
    {
        key: 6,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK6.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK6.webp",
        loading: "lazy"
    },
    {
        key: 7,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK7.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK7.webp",
        loading: "lazy"
    },
    {
        key: 8,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK8.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK8.webp",
        loading: "lazy"
    },
    {
        key: 9,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK9.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK9.webp",
        loading: "lazy"
    },




    {
        key: 10,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK10.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK10.webp",
        loading: "lazy"
    },
    {
        key: 11,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK11.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK11.webp",
        loading: "lazy"
    },
    {
        key: 12,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK12.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK12.webp",
        loading: "lazy"
    },

    {
        key: 14,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK13.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK13.webp",
        loading: "lazy"
    },
    {
        key: 15,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK14.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK14.webp",
        loading: "lazy"
    },
    {
        key: 16,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK15.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK15.webp",
        loading: "lazy"
    },
    {
        key: 17,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK16.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK16.webp",
        loading: "lazy"
    },
    {
        key: 18,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK17.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK17.webp",
        loading: "lazy"
    },
    {
        key: 19,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK18.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK18.webp",
        loading: "lazy"
    },
    {
        key: 20,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK19.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK19.webp",
        loading: "lazy"
    },
    {
        key: 21,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK20.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK20.webp",
        loading: "lazy"
    },
    {
        key: 22,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK21.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK21.webp",
        loading: "lazy"
    },
    {
        key: 23,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK22.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK22.webp",
        loading: "lazy"
    },
    {
        key: 24,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK23.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK23.webp",
        loading: "lazy"
    },
    {
        key: 25,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK24.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK24.webp",
        loading: "lazy"
    },
    {
        key: 26,
        srcJpeg: "../../content/photos/collections/SS25/jpg/XIMONLEE_LOOK25.jpg",
        srcWebp: "../../content/photos/collections/SS25/webp/XIMONLEE_LOOK25.webp",
        loading: "lazy"
    },




];


export default ss25CollectionImages;
