import React, { useState, useEffect, lazy, Suspense } from "react";
/* import { Helmet } from 'react-helmet';
import MetaTags from 'react-meta-tags'; */

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import './App.scss';

/* PAGES */

import Home from "./pages/home";
import Gallery from "./pages/gallery";
import Eshop from "./pages/eshop";

import Current from "./pages/current";
import AW24 from "./pages/aw24";
import SS24 from "./pages/ss24";
import AW23 from "./pages/aw23";
import SS23 from "./pages/ss23";
import PRE23 from "./pages/pre23";
import SS22 from "./pages/ss22";
import AW21 from "./pages/aw21";

import Connect from "./pages/connect";
import ShippingAndReturn from "./pages/shipping-returns";

/* import LoadingTest from "./pages/loadingtest";
import CurrentNew from "./pages/currentnew";
import CurrentObserver from "./pages/currentobserver"; */

import PageNotFound from './pages/page-not-found';


// import Observer from "./pages/observer";
// import Observer2 from "./pages/observer2";


/* ESHOP PAGES */
import Jackets from "./pages/eshop/jackets";
import Pants from "./pages/eshop/pants";
import Tops from "./pages/eshop/tops";
import Accessories from "./pages/eshop/accessories";
import Sales from "./pages/eshop/sales";

/* AW21 */
/* JACKETS */
import CutUpChainedOvercoatBlack from "./pages/eshop/AW21/jackets/cut-up-chained-overcoat-black";
import OStuddedLeatherJacket from "./pages/eshop/AW21/jackets/o-studded-leather-jacket";
import PeepholeUniformJacket from "./pages/eshop/AW21/jackets/peephole-uniform-jacket";
import DetachableCollarWindJacketBlack from "./pages/eshop/AW21/jackets/detachable-collar-wind-jacket-black";
import DetachableCollarWindJacketArmyGreen from "./pages/eshop/AW21/jackets/detachable-collar-wind-jacket-army-green";
import CutUpChainedOvercoatStripe from "./pages/eshop/AW21/jackets/cut-up-chained-overcoat-stripe";
import DrummerBomberJacketRed from "./pages/eshop/AW21/jackets/drummer-bomber-jacket-red";
import DrummerBomberJacketArmyGreen from "./pages/eshop/AW21/jackets/drummer-bomber-jacket-army-green";
import DrummerBomberJacketChampagne from "./pages/eshop/AW21/jackets/drummer-bomber-jacket-champagne";
import ScrapbookJacquardJacket from "./pages/eshop/AW21/jackets/scrapbook-jacquard-jacket";

/* PANTS */
import BikerPanelledLeatherTrousers from "./pages/eshop/AW21/pants/biker-panelled-leather-trousers"
import BikerPanelledTrousersCreamWhite from "./pages/eshop/AW21/pants/biker-panelled-trousers-cream-white"
import BikerPanelledTrousersDarkBlue from "./pages/eshop/AW21/pants/biker-panelled-trousers-dark-blue"
import BikerPanelledTrousersRed from "./pages/eshop/AW21/pants/biker-panelled-trousers-red"
import CheckeredPeepholeTrousers from "./pages/eshop/AW21/pants/checkered-peephole-trousers"
import MixedCheckeredTrousers from "./pages/eshop/AW21/pants/mixed-checkered-trousers"
import PeepholeJeans from "./pages/eshop/AW21/pants/peephole-jeans"
import PeepholeLeatherTrousers from "./pages/eshop/AW21/pants/peephole-leather-trousers"
import PeepholeVJogger from "./pages/eshop/AW21/pants/peephole-v-jogger"
import PeepholeVTrousers from "./pages/eshop/AW21/pants/peephole-v-trousers"
import ScrapbookComboBikerPants from "./pages/eshop/AW21/pants/scrapbook-combo-biker-pants"


/* TOPS */
import ShadowWaveJumper from "./pages/eshop/AW21/tops/shadow-wave-jumper"
import ShadowWaveZipupBlackWhite from "./pages/eshop/AW21/tops/shadow-wave-zipup-black-white"
import ShadowWaveZipupCaramelRed from "./pages/eshop/AW21/tops/shadow-wave-zipup-caramel-red"
import KnitTankBlueGrey from "./pages/eshop/AW21/tops/knit-tank-blue-grey"
import KnitTankBrownCream from "./pages/eshop/AW21/tops/knit-tank-brown-cream"
import KnitTankRedOlive from "./pages/eshop/AW21/tops/knit-tank-red-olive"
import KnitTankWhiteGrey from "./pages/eshop/AW21/tops/knit-tank-white-grey"
import KnitTankYellow from "./pages/eshop/AW21/tops/knit-tank-yellow"
import KnitOliveBlack from "./pages/eshop/AW21/tops/knit-tank-olive-black"
import BodyTankBlack from "./pages/eshop/AW21/tops/body-tank-black"
import BodyTankGreen from "./pages/eshop/AW21/tops/body-tank-green"
import BodyTankRed from "./pages/eshop/AW21/tops/body-tank-red"
import BodyTankWhite from "./pages/eshop/AW21/tops/body-tank-white"
import CheckeredVest from "./pages/eshop/AW21/tops/checkered-vest"
import JigsawPlantSweater from "./pages/eshop/AW21/tops/jigsaw-plant-sweater"
import JigsawPlantVest from "./pages/eshop/AW21/tops/jigsaw-plant-vest"
import MixedCheckeredShirt from "./pages/eshop/AW21/tops/mixed-checkered-shirt"
import PeepholeLeatherTank from "./pages/eshop/AW21/tops/peephole-leather-tank"
import PeepholeZipupJacketShirt from "./pages/eshop/AW21/tops/peephole-zipup-jacket-shirt"
import ScrapbookJacquardShirt from "./pages/eshop/AW21/tops/scrapbook-jacquard-shirt"
import ScrapbookPeepholeTank from "./pages/eshop/AW21/tops/scrapbook-peephole-tank"
import SunsetPrintTshirtBlackI from "./pages/eshop/AW21/tops/sunset-print-tshirt-black-I"
import SunsetPrintTshirtBlackII from "./pages/eshop/AW21/tops/sunset-print-tshirt-black-II"
import SunsetPrintTshirtBlackIII from "./pages/eshop/AW21/tops/sunset-print-tshirt-black-III"
import SunsetPrintTshirtBlackIV from "./pages/eshop/AW21/tops/sunset-print-tshirt-black-IV"
import SunsetPrintTshirtWhiteI from "./pages/eshop/AW21/tops/sunset-print-tshirt-white-I"
import SunsetPrintTshirtWhiteII from "./pages/eshop/AW21/tops/sunset-print-tshirt-white-II"
import SunsetPrintTshirtWhiteIII from "./pages/eshop/AW21/tops/sunset-print-tshirt-white-III"
import UniformShirtBlackBrownCombo from "./pages/eshop/AW21/tops/uniform-shirt-black-brown-combo"
import UniformShirtCheckered from "./pages/eshop/AW21/tops/uniform-shirt-checkered"
import UniformShirtSand from "./pages/eshop/AW21/tops/uniform-shirt-sand"

/* SS22 */
/* JACKETS */
import ChainlockLeatherCoat from "./pages/eshop/SS22/jackets/chainlock-leather-coat"
import DetachableSleeveBomberGrey from "./pages/eshop/SS22/jackets/detachable-sleeve-bomber-grey"
import DetachableSleeveBomberPink from "./pages/eshop/SS22/jackets/detachable-sleeve-bomber-pink"
import DetachableSleeveBomberBlack from "./pages/eshop/SS22/jackets/detachable-sleeve-bomber-black"
import PanneledWoolCoat from "./pages/eshop/SS22/jackets/panneled-wool-coat"
import LeatherZipShirtJacket from "./pages/eshop/SS22/jackets/leather-zip-shirt-jacket"
import LambSkinWorkJacketGreen from "./pages/eshop/SS22/jackets/lamb-skin-work-jacket-green"
import LambSkinWorkJacketBlack from "./pages/eshop/SS22/jackets/lamb-skin-work-jacket-black"
import ChainlockDenimJacket from "./pages/eshop/SS22/jackets/chainlock-denim-jacket"
import CharcoalLizardSpecimenJacket from "./pages/eshop/SS22/jackets/charcoal-lizard-specimen-jacket";
import DoubleBreastedLockBlazer from "./pages/eshop/SS22/jackets/double-breasted-lock-blazer";

/* TOPS */
import MohairLooseKnitJumperSand from "./pages/eshop/SS22/tops/mohair-loose-knit-jumper-sand";
import MohairLooseKnitJumperOrange from "./pages/eshop/SS22/tops/mohair-loose-knit-jumper-orange";
import CableKnitTankBlack from "./pages/eshop/SS22/tops/cable-knit-tank-black";
import CableKnitTankCream from "./pages/eshop/SS22/tops/cable-knit-tank-cream";
import MotorbikeCompressionTop from "./pages/eshop/SS22/tops/motorbike-compression-top";
import TwopieceLeatherVestOrange from "./pages/eshop/SS22/tops/twopiece-leather-vest-orange";
import TwopieceLeatherVestBlack from "./pages/eshop/SS22/tops/twopiece-leather-vest-black";
import SharkSpecimenResortShirt from "./pages/eshop/SS22/tops/shark-specimen-resort-shirt";
import WetSpecimenTee from "./pages/eshop/SS22/tops/wet-specimen-tee";
import AsymetricalBleachedTank from "./pages/eshop/SS22/tops/asymetrical-bleached-tank";
import IronGateCompressionTop from "./pages/eshop/SS22/tops/iron-gate-compression-top";
import ChainlockLeatherVest from "./pages/eshop/SS22/tops/chainlock-leather-vest";
import SkyscrapersCompressionTop from "./pages/eshop/SS22/tops/skyscrapers-compression-top";
import SharkSpecimenShirt from "./pages/eshop/SS22/tops/shark-specimen-shirt";
import LizardSpecimenCompressionTop from "./pages/eshop/SS22/tops/lizard-specimen-compression-top";
import CharcoalLizardSpecimenOvershirt from "./pages/eshop/SS22/tops/charcoal-lizard-specimen-overshirt";
import ChainlockTee from "./pages/eshop/SS22/tops/chainlock-tee";
import LizardSpecimenTee from "./pages/eshop/SS22/tops/lizard-specimen-tee";
import SkyscrapersTee from "./pages/eshop/SS22/tops/skyscrapers-tee";

/* PANTS */
import ChainlockNylonPants from "./pages/eshop/SS22/pants/chainlock-nylon-pants";
import SharkSpecimenBoxerShorts from "./pages/eshop/SS22/pants/shark-specimen-boxer-shorts";
import PanneledLeatherBikerPants from "./pages/eshop/SS22/pants/panneled-leather-biker-pants";
import CharcoalLizardSpecimenPants from "./pages/eshop/SS22/pants/charcoal-lizard-specimen-pants";
import ChainlockJeans from "./pages/eshop/SS22/pants/chainlock-jeans";
import EyeletDenimShorts from "./pages/eshop/SS22/pants/eyelet-denim-shorts";
import ChainlockLeatherShorts from "./pages/eshop/SS22/pants/chainlock-leather-shorts";
import CharcoalWetSpecimenShorts from "./pages/eshop/SS22/pants/charcoal-wet-specimen-shorts";


/* AW22 */
/* JACKETS */
import CharcoalLizardBlazer from "./pages/eshop/AW22/jackets/charcoal-lizard-blazer";
import BikerJacketWithArmStraps from "./pages/eshop/AW22/jackets/biker-jacket-with-arm-straps";
import BomberWithRestrainedArms from "./pages/eshop/AW22/jackets/bomber-with-restrained-arms";
import DropShoulderPUCoat from "./pages/eshop/AW22/jackets/drop-shoulder-pu-coat";
import LeatherBomberWithRestrainedArms from "./pages/eshop/AW22/jackets/leather-bomber-with-restrained-arms";
import PanelledMacCoat from "./pages/eshop/AW22/jackets/panelled-mac-coat";

/* PANTS */
import AdjustableDrawstringPants from "./pages/eshop/AW22/pants/adjustable-drawstring-pants";
import CamouflageCowhidePants from "./pages/eshop/AW22/pants/camouflage-cowhide-pants";
import LeatherBikerPants from "./pages/eshop/AW22/pants/leather-biker-pants";
import MudRootPrintedJeans from "./pages/eshop/AW22/pants/mud-root-printed-jeans";

/* TOPS */
import AdjustableDrawstringTopBeige from "./pages/eshop/AW22/tops/adjustable-drawstring-top-beige";
import AdjustableDrawstringTopGrey from "./pages/eshop/AW22/tops/adjustable-drawstring-top-grey";
import CamouflageZipUpWorkShirt from "./pages/eshop/AW22/tops/camouflage-zip-up-work-shirt";
import KnittedTankWithShoulderCap from "./pages/eshop/AW22/tops/knitted-tank-with-shoulder-cap";
import MixedRibZipUpCardigan from "./pages/eshop/AW22/tops/mixed-rib-zip-up-cardigan";
import MudRootCompressionTop from "./pages/eshop/AW22/tops/mud-root-compression-top";
import MudRootEmbroideredTee from "./pages/eshop/AW22/tops/mud-root-embroidered-tee";
import NightShoreCompressionTop from "./pages/eshop/AW22/tops/night-shore-compression-top";
import NightShoreSilkShirt from "./pages/eshop/AW22/tops/night-shore-silk-shirt";
import SnowWoodEmbroideredTee from "./pages/eshop/AW22/tops/snow-wood-embroidered-tee";
import NightShoreEmbroideredTee from "./pages/eshop/AW22/tops/night-shore-embroidered-tee";

/* SS23 */
/* JACKETS */
import ChainCuffLeatherJacket from "./pages/eshop/SS23/jackets/chain-cuff-leather-jacket";
import PanelledCoat from "./pages/eshop/SS23/jackets/panelled-coat";
import PanelledLeatherCoat from "./pages/eshop/SS23/jackets/panelled-leather-coat";
import NylonBomberVest from "./pages/eshop/SS23/jackets/nylon-bomber-vest";


/* PANTS */
import ChainlockBikerPants from "./pages/eshop/SS23/pants/chainlock-biker-pants";
import ChainlockLeatherBikerPants from "./pages/eshop/SS23/pants/chainlock-leather-biker-pants";
import CowhidePencilSkirt from "./pages/eshop/SS23/pants/cowhide-pencil-skirt";
import GatheredLeatherPants from "./pages/eshop/SS23/pants/gathered-leather-pants";
import GatheredPants from "./pages/eshop/SS23/pants/gathered-pants";
import TyreSkirt from "./pages/eshop/SS23/pants/tyre-skirt";
import ChainlockPencilSkirt from "./pages/eshop/SS23/pants/chainlock-pencil-skirt";


/* TOPS */
import GatheredSleeveShirtDarkBrown from "./pages/eshop/SS23/tops/gathered-sleeve-shirt-dark-brown";
import GatheredSleeveShirtRedBrown from "./pages/eshop/SS23/tops/gathered-sleeve-shirt-red-brown";
import GatheredSleeveShirtWhite from "./pages/eshop/SS23/tops/gathered-sleeve-shirt-white";
import LeatherBondedCableKnitVest from "./pages/eshop/SS23/tops/leather-bonded-cable-knit-vest";
import LeatherVest from "./pages/eshop/SS23/tops/leather-vest";
import PanelledTee from "./pages/eshop/SS23/tops/panelled-tee";
import PhotographicPrintTee from "./pages/eshop/SS23/tops/photographic-print-tee"
import RibKnitVest from "./pages/eshop/SS23/tops/rib-knit-vest";
import SleazyNetKnitApricot from "./pages/eshop/SS23/tops/sleazy-net-knit-apricot";
import SleazyNetKnitBlack from "./pages/eshop/SS23/tops/sleazy-net-knit-black";
import SleazyNetKnitGreen from "./pages/eshop/SS23/tops/sleazy-net-knit-green";
import VestDress from "./pages/eshop/SS23/tops/vest-dress";
import CowhideVest from "./pages/eshop/SS23/tops/cowhide-vest";

/* AW23 */
/* JACKETS */
import FlameBikerJacket from "./pages/eshop/AW23/jackets/flame-biker-jacket";
import LoopsBomber from "./pages/eshop/AW23/jackets/loops-bomber";
import MemoryLeatherJacket from "./pages/eshop/AW23/jackets/memory-leather-jacket";
import GrippedArmJacket from "./pages/eshop/AW23/jackets/gripped-arm-jacket"
import SculpturedLeatherBlazer from "./pages/eshop/AW23/jackets/sculptured-leather-blazer"

/* PANTS */
import ChainlockBikerPantsAW23 from "./pages/eshop/AW23/pants/chainlock-biker-pants-aw23";
import PUBeltedCombatPants from "./pages/eshop/AW23/pants/pu-belted-combat-pants";
import StonewashedBeltedCombatPants from "./pages/eshop/AW23/pants/stonewashed-belted-combat-pants";
import BeltedCombatLeatherPants from "./pages/eshop/AW23/pants/belted-combat-leather-pants";
import CurtainPants from "./pages/eshop/AW23/pants/curtain-pants";
import LeatherGrippedPants from "./pages/eshop/AW23/pants/leather-gripped-pants";
import WaterproofGrippedPants from "./pages/eshop/AW23/pants/waterproof-gripped-pants";

/* TOPS */
import CutOutRibKnit from "./pages/eshop/AW23/tops/cut-out-rib-knit";
import PrintedSweatshirt from "./pages/eshop/AW23/tops/printed-sweatshirt";
import SignatureTank from "./pages/eshop/AW23/tops/signature-tank";
import SlouchyLongTee from "./pages/eshop/AW23/tops/slouchy-long-tee";
import BaroqueShirtBlack from "./pages/eshop/AW23/tops/baroque-shirt-black";
import BaroqueShirtWhite from "./pages/eshop/AW23/tops/baroque-shirt-white";
import ChunkyRibKnitBlack from "./pages/eshop/AW23/tops/chunky-rib-knit-black";
import ChunkyRibKnitRed from "./pages/eshop/AW23/tops/chunky-rib-knit-red";
import CurtainShirt from "./pages/eshop/AW23/tops/curtain-shirt";
import CutOutRibKnitBlack from "./pages/eshop/AW23/tops/cut-out-rib-knit-black";
import LogoZipUpKnit from "./pages/eshop/AW23/tops/logo-zip-up-knit";
import PrintedHoodie from "./pages/eshop/AW23/tops/printed-hoodie";
import PrintedTee from "./pages/eshop/AW23/tops/printed-tee";
import SignatureTankBlack from "./pages/eshop/AW23/tops/signature-tank-black";
import TrippleBeltedHighNeckVest from "./pages/eshop/AW23/tops/tripple-belted-high-neck-vest";

/* ACCESSORIES */
import LeatherBraToteBagBlack from "./pages/eshop/AW23/accessories/leather-bra-tote-bag-black";
import LeatherBraToteBagRed from "./pages/eshop/AW23/accessories/leather-bra-tote-bag-red";
import StonewashedBraToteBag from "./pages/eshop/AW23/accessories/stonewashed-bra-tote-bag";
//import DenimBraToteBag from "./pages/eshop/AW23/accessories/denim-bra-tote-bag";
import BraToteBag from "./pages/eshop/AW23/accessories/bra-tote-bag";

/* SS24 */
/* JACKETS */
import CroppedChainLockBikerJacket from "./pages/eshop/SS24/jackets/cropped-chainlock-biker-jacket";
import CroppedChainLockMiniJacket from "./pages/eshop/SS24/jackets/cropped-chainlock-mini-jacket";
import LeatherLapelJacket from "./pages/eshop/SS24/jackets/leather-lapel-jacket";
import SummerBikerJacket from "./pages/eshop/SS24/jackets/summer-biker-jacket";
import WarriorJacket from "./pages/eshop/SS24/jackets/warrior-jacket";

/* PANTS */
import CreasedLeatherPants from "./pages/eshop/SS24/pants/creased-leather-pants";
import CreasedPants from "./pages/eshop/SS24/pants/creased-pants";
import FoldedWaistSkirt from "./pages/eshop/SS24/pants/folded-waist-skirt";

import OverKneeLeatherShorts from "./pages/eshop/SS24/pants/over-knee-leather-shorts";
import OverKneeShorts from "./pages/eshop/SS24/pants/over-knee-shorts";
import PleatedVolumePants from "./pages/eshop/SS24/pants/pleated-volume-pants";

/* TOPS */
import ChainlockVest from "./pages/eshop/SS24/tops/chainlock-vest";
import CreasedLayeredMuscleTank from "./pages/eshop/SS24/tops/creased-layered-muscle-tank";
import CreasedMeshDress from "./pages/eshop/SS24/tops/creased-mesh-dress";
import CreasedZipHoodie from "./pages/eshop/SS24/tops/creased-zip-hoodie";
import DistressedCroppedHoodie from "./pages/eshop/SS24/tops/distressed-cropped-hoodie";
import DrapeTankLightbrown from "./pages/eshop/SS24/tops/drape-tank-lightbrown";
import DrapeTankLightgrey from "./pages/eshop/SS24/tops/drape-tank-lightgrey";
import DrapeTankWhite from "./pages/eshop/SS24/tops/drape-tank-white";
import FoldedWaistDress from "./pages/eshop/SS24/tops/folded-waist-dress";
import MeshLayeredTee from "./pages/eshop/SS24/tops/mesh-layered-tee";
import SlouchyLongTeeSS24 from "./pages/eshop/SS24/tops/slouchy-long-tee-ss24";
import TwinFoldTeeGrey from "./pages/eshop/SS24/tops/twin-fold-tee-grey";
import TwinFoldTeeWhite from "./pages/eshop/SS24/tops/twin-fold-tee-white";

/* ACCESSORIES */
import MiniBraDenimToteBag from "./pages/eshop/SS24/accessories/mini-bra-denim-tote-bag";
import MiniBraToteBag from "./pages/eshop/SS24/accessories/mini-bra-tote-bag";

/* COMPONENT */
import BottomMenu from "./components/MenuBottom";
import Logo from "./components/Logo";


/* const Gallery = lazy(() => import("./pages/gallery"));
const Eshop = lazy(() => import("./pages/eshop"));
const SS22 = lazy(() => import("./pages/ss22"));
const AW21 = lazy(() => import("./pages/aw21"));
const PRE23 = lazy(() => import("./pages/pre23"));
const Current = lazy(() => import("./pages/current")); */

function App() {

  window.addEventListener(
    'contextmenu',
    function (e) {
      e.preventDefault();
    },
    false
  );

  const ExternalRedirect = ({ to }) => {
    useEffect(() => {
      window.location.href = to;
    }, [to]);

    return null;
  };

  return (

    <div>
      <Router>

        <Logo />
        {/* <BottomMenu /> */}

        <Switch>

          <Route exact path="/" component={Home} />

          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/connect" component={Connect} />
          <Route exact path="/shipping-returns" component={ShippingAndReturn} />

          {/*    <Route exact path="/currentnew" component={CurrentNew} />
          <Route exact path="/currentobserver" component={CurrentObserver} />
          <Route exact path="/loadingtest" component={LoadingTest} /> */}

          <Route exact path="/current" component={Current} />
          <Route exact path="/aw24" component={AW24} />
          <Route exact path="/ss24" component={SS24} />
          <Route exact path="/aw23" component={AW23} />
          <Route exact path="/ss23" component={SS23} />
          <Route exact path="/pre23" component={PRE23} />
          <Route exact path="/ss22" component={SS22} />
          <Route exact path="/aw21" component={AW21} />

          <Route
            exact
            path="/eshop"
            render={() => <ExternalRedirect to="https://shop.ximonlee.com/" />}
          />

          {/*    <Route exact path="/eshop" component={Eshop} />
         <Route exact path="/jackets" component={Jackets} />
          <Route exact path="/pants" component={Pants} />
          <Route exact path="/tops" component={Tops} />
          <Route exact path="/accessories" component={Accessories} />
          <Route exact path="/sales" component={Sales} /> */}



          {/*   <Route path="/observer" component={Observer} />
            <Route path="/observer2" component={Observer2} /> */}

          {  /* AW21 */}
          {  /* JACKETS */}
          {/*  <Route path="/cut-up-chained-overcoat-black" component={CutUpChainedOvercoatBlack} />
          <Route path="/cut-up-chained-overcoat-stripe" component={CutUpChainedOvercoatStripe} />
          <Route path="/o-studded-leather-jacket" component={OStuddedLeatherJacket} />
          <Route path="/peephole-uniform-jacket" component={PeepholeUniformJacket} />
          <Route path="/detachable-collar-wind-jacket-black" component={DetachableCollarWindJacketBlack} />
          <Route path="/detachable-collar-wind-jacket-army-green" component={DetachableCollarWindJacketArmyGreen} />
          <Route path="/drummer-bomber-jacket-red" component={DrummerBomberJacketRed} />
          <Route path="/drummer-bomber-jacket-army-green" component={DrummerBomberJacketArmyGreen} />
          <Route path="/drummer-bomber-jacket-champagne" component={DrummerBomberJacketChampagne} />
          <Route path="/scrapbook-jacquard-jacket" component={ScrapbookJacquardJacket} /> */}

          {  /* PANTS */}
          {/*  <Route path="/biker-panelled-leather-trousers" component={BikerPanelledLeatherTrousers} />
          <Route path="/biker-panelled-trousers-cream-white" component={BikerPanelledTrousersCreamWhite} />
          <Route path="/biker-panelled-trousers-dark-blue" component={BikerPanelledTrousersDarkBlue} />
          <Route path="/biker-panelled-trousers-red" component={BikerPanelledTrousersRed} />
          <Route path="/checkered-peephole-trousers" component={CheckeredPeepholeTrousers} />
          <Route path="/mixed-checkered-trousers" component={MixedCheckeredTrousers} />
          <Route path="/peephole-jeans" component={PeepholeJeans} />
          <Route path="/peephole-leather-trousers" component={PeepholeLeatherTrousers} />
          <Route path="/peephole-v-jogger" component={PeepholeVJogger} />
          <Route path="/peephole-v-trousers" component={PeepholeVTrousers} />
          <Route path="/scrapbook-combo-biker-pants" component={ScrapbookComboBikerPants} />
 */}
          {  /* TOPS */}
          {/*  <Route path="/shadow-wave-jumper" component={ShadowWaveJumper} />
          <Route path="/shadow-wave-zipup-black-white" component={ShadowWaveZipupBlackWhite} />
          <Route path="/shadow-wave-zipup-caramel-red" component={ShadowWaveZipupCaramelRed} />
          <Route path="/peephole-zipup-jacket-shirt" component={PeepholeZipupJacketShirt} />
          <Route path="/peephole-leather-tank" component={PeepholeLeatherTank} />
          <Route path="/knit-tank-blue-grey" component={KnitTankBlueGrey} />
          <Route path="/knit-tank-brown-cream" component={KnitTankBrownCream} />
          <Route path="/knit-tank-red-olive" component={KnitTankRedOlive} />
          <Route path="/knit-tank-white-grey" component={KnitTankWhiteGrey} />
          <Route path="/knit-tank-yellow" component={KnitTankYellow} />
          <Route path="/knit-tank-olive-black" component={KnitOliveBlack} />
          <Route path="/body-tank-black" component={BodyTankBlack} />
          <Route path="/body-tank-green" component={BodyTankGreen} />
          <Route path="/body-tank-red" component={BodyTankRed} />
          <Route path="/body-tank-white" component={BodyTankWhite} />
          <Route path="/checkered-vest" component={CheckeredVest} />
          <Route path="/jigsaw-plant-sweater" component={JigsawPlantSweater} />
          <Route path="/jigsaw-plant-vest" component={JigsawPlantVest} />
          <Route path="/mixed-checkered-shirt" component={MixedCheckeredShirt} />
          <Route path="/scrapbook-jacquard-shirt" component={ScrapbookJacquardShirt} />
          <Route path="/scrapbook-peephole-tank" component={ScrapbookPeepholeTank} />
          <Route path="/sunset-print-tshirt-black-i" component={SunsetPrintTshirtBlackI} />
          <Route path="/sunset-print-tshirt-black-ii" component={SunsetPrintTshirtBlackII} />
          <Route path="/sunset-print-tshirt-black-iii" component={SunsetPrintTshirtBlackIII} />
          <Route path="/sunset-print-tshirt-black-iv" component={SunsetPrintTshirtBlackIV} />
          <Route path="/sunset-print-tshirt-white-i" component={SunsetPrintTshirtWhiteI} />
          <Route path="/sunset-print-tshirt-white-ii" component={SunsetPrintTshirtWhiteII} />
          <Route path="/sunset-print-tshirt-white-iii" component={SunsetPrintTshirtWhiteIII} />
          <Route path="/uniform-shirt-black-brown-combo" component={UniformShirtBlackBrownCombo} />
          <Route path="/uniform-shirt-checkered" component={UniformShirtCheckered} />
          <Route path="/uniform-shirt-sand" component={UniformShirtSand} />  */}


          {  /* SS22 */}
          {  /* JACKETS */}
          <Route path="/chainlock-leather-coat" component={ChainlockLeatherCoat} />
          <Route path="/detachable-sleeve-bomber-grey" component={DetachableSleeveBomberGrey} />
          <Route path="/detachable-sleeve-bomber-pink" component={DetachableSleeveBomberPink} />
          <Route path="/detachable-sleeve-bomber-black" component={DetachableSleeveBomberBlack} />
          <Route path="/panneled-wool-coat" component={PanneledWoolCoat} />
          <Route path="/leather-zip-shirt-jacket" component={LeatherZipShirtJacket} />
          <Route path="/lamb-skin-work-jacket-green" component={LambSkinWorkJacketGreen} />
          <Route path="/lamb-skin-work-jacket-black" component={LambSkinWorkJacketBlack} />
          <Route path="/chainlock-denim-jacket" component={ChainlockDenimJacket} />
          <Route path="/charcoal-lizard-specimen-jacket" component={CharcoalLizardSpecimenJacket} />
          <Route path="/double-breasted-lock-blazer" component={DoubleBreastedLockBlazer} />

          {  /* TOPS */}
          <Route path="/mohair-loose-knit-jumper-sand" component={MohairLooseKnitJumperSand} />
          <Route path="/mohair-loose-knit-jumper-orange" component={MohairLooseKnitJumperOrange} />
          <Route path="/cable-knit-tank-black" component={CableKnitTankBlack} />
          <Route path="/cable-knit-tank-cream" component={CableKnitTankCream} />
          <Route path="/motorbike-compression-top" component={MotorbikeCompressionTop} />
          <Route path="/cable-knit-tank-cream" component={CableKnitTankCream} />
          <Route path="/twopiece-leather-vest-orange" component={TwopieceLeatherVestOrange} />
          <Route path="/twopiece-leather-vest-black" component={TwopieceLeatherVestBlack} />
          <Route path="/shark-specimen-resort-shirt" component={SharkSpecimenResortShirt} />
          <Route path="/wet-specimen-tee" component={WetSpecimenTee} />
          <Route path="/asymetrical-bleached-tank" component={AsymetricalBleachedTank} />
          <Route path="/iron-gate-compression-top" component={IronGateCompressionTop} />
          <Route path="/chainlock-leather-vest" component={ChainlockLeatherVest} />
          <Route path="/skyscrapers-compression-top" component={SkyscrapersCompressionTop} />
          <Route path="/shark-specimen-shirt" component={SharkSpecimenShirt} />
          <Route path="/lizard-specimen-compression-top" component={LizardSpecimenCompressionTop} />
          <Route path="/charcoal-lizard-specimen-overshirt" component={CharcoalLizardSpecimenOvershirt} />
          <Route path="/chainlock-tee" component={ChainlockTee} />
          <Route path="/lizard-specimen-tee" component={LizardSpecimenTee} />
          <Route path="/skyscrapers-tee" component={SkyscrapersTee} />

          {  /* PANTS */}
          <Route path="/chainlock-nylon-pants" component={ChainlockNylonPants} />
          <Route path="/shark-specimen-boxer-shorts" component={SharkSpecimenBoxerShorts} />
          <Route path="/panneled-leather-biker-pants" component={PanneledLeatherBikerPants} />
          <Route path="/charcoal-lizard-specimen-pants" component={CharcoalLizardSpecimenPants} />
          <Route path="/chainlock-jeans" component={ChainlockJeans} />
          <Route path="/eyelet-denim-shorts" component={EyeletDenimShorts} />
          <Route path="/chainlock-leather-shorts" component={ChainlockLeatherShorts} />
          <Route path="/charcoal-wet-specimen-shorts" component={CharcoalWetSpecimenShorts} />

          {  /* AW22 */}
          {  /* JACKETS */}
          <Route path="/charcoal-lizard-blazer" component={CharcoalLizardBlazer} />
          <Route path="/biker-jacket-with-arm-straps" component={BikerJacketWithArmStraps} />
          <Route path="/bomber-with-restrained-arms" component={BomberWithRestrainedArms} />
          <Route path="/drop-shoulder-pu-coat" component={DropShoulderPUCoat} />
          <Route path="/leather-bomber-with-restrained-arms" component={LeatherBomberWithRestrainedArms} />
          <Route path="/panelled-mac-coat" component={PanelledMacCoat} />

          {  /* PANTS */}
          <Route path="/adjustable-drawstring-pants" component={AdjustableDrawstringPants} />
          <Route path="/camouflage-cowhide-pants" component={CamouflageCowhidePants} />
          <Route path="/leather-biker-pants" component={LeatherBikerPants} />
          <Route path="/mud-root-printed-jeans" component={MudRootPrintedJeans} />

          {  /* TOPS */}
          <Route path="/adjustable-drawstring-top-beige" component={AdjustableDrawstringTopBeige} />
          <Route path="/adjustable-drawstring-top-grey" component={AdjustableDrawstringTopGrey} />
          <Route path="/camouflage-zip-up-work-shirt" component={CamouflageZipUpWorkShirt} />
          <Route path="/knitted-tank-with-shoulder-cap" component={KnittedTankWithShoulderCap} />
          <Route path="/mixed-rib-zip-up-cardigan" component={MixedRibZipUpCardigan} />
          <Route path="/mud-root-compression-top" component={MudRootCompressionTop} />
          <Route path="/mud-root-embroidered-tee" component={MudRootEmbroideredTee} />
          <Route path="/night-shore-compression-top" component={NightShoreCompressionTop} />
          <Route path="/night-shore-embroidered-tee" component={NightShoreEmbroideredTee} />
          <Route path="/night-shore-silk-shirt" component={NightShoreSilkShirt} />
          <Route path="/snow-wood-embroidered-tee" component={SnowWoodEmbroideredTee} />

          {  /* SS23 */}
          {  /* JACKETS */}
          <Route path="/chain-cuff-leather-jacket" component={ChainCuffLeatherJacket} />
          <Route path="/panelled-coat" component={PanelledCoat} />
          <Route path="/panelled-leather-coat" component={PanelledLeatherCoat} />
          <Route path="/nylon-bomber-vest" component={NylonBomberVest} />


          {  /* PANTS */}
          <Route path="/chainlock-biker-pants" component={ChainlockBikerPants} />
          <Route path="/chainlock-leather-biker-pants" component={ChainlockLeatherBikerPants} />
          <Route path="/cowhide-pencil-skirt" component={CowhidePencilSkirt} />
          <Route path="/gathered-leather-pants" component={GatheredLeatherPants} />
          <Route path="/gathered-pants" component={GatheredPants} />
          <Route path="/tyre-skirt" component={TyreSkirt} />
          <Route path="/chainlock-pencil-skirt" component={ChainlockPencilSkirt} />


          {  /* TOPS */}
          <Route path="/gathered-sleeve-shirt-dark-brown" component={GatheredSleeveShirtDarkBrown} />
          <Route path="/gathered-sleeve-shirt-red-brown" component={GatheredSleeveShirtRedBrown} />
          <Route path="/gathered-sleeve-shirt-white" component={GatheredSleeveShirtWhite} />
          <Route path="/leather-bonded-cable-knit-vest" component={LeatherBondedCableKnitVest} />
          <Route path="/leather-vest" component={LeatherVest} />
          <Route path="/panelled-tee" component={PanelledTee} />
          <Route path="/photographic-print-tee" component={PhotographicPrintTee} />
          <Route path="/rib-knit-vest" component={RibKnitVest} />
          <Route path="/sleazy-net-knit-apricot" component={SleazyNetKnitApricot} />
          <Route path="/sleazy-net-knit-black" component={SleazyNetKnitBlack} />
          <Route path="/sleazy-net-knit-green" component={SleazyNetKnitGreen} />
          <Route path="/vest-dress" component={VestDress} />
          <Route path="/cowhide-vest" component={CowhideVest} />

          {  /* AW23 */}
          {  /* JACKETS */}
          <Route path="/flame-biker-jacket" component={FlameBikerJacket} />
          <Route path="/loops-bomber" component={LoopsBomber} />
          <Route path="/memory-leather-jacket" component={MemoryLeatherJacket} />
          <Route path="/gripped-arm-jacket" component={GrippedArmJacket} />
          <Route path="/sculptured-leather-blazer" component={SculpturedLeatherBlazer} />


          {  /* PANTS */}
          <Route path="/chainlock-biker-pants-aw23" component={ChainlockBikerPantsAW23} />
          <Route path="/pu-belted-combat-pants" component={PUBeltedCombatPants} />
          <Route path="/stonewashed-belted-combat-pants" component={StonewashedBeltedCombatPants} />
          <Route path="/belted-combat-leather-pants" component={BeltedCombatLeatherPants} />
          <Route path="/curtain-pants" component={CurtainPants} />
          <Route path="/leather-gripped-pants" component={LeatherGrippedPants} />
          <Route path="/waterproof-gripped-pants" component={WaterproofGrippedPants} />

          {  /* TOPS */}
          <Route path="/cut-out-rib-knit" component={CutOutRibKnit} />
          <Route path="/printed-sweatshirt" component={PrintedSweatshirt} />
          <Route path="/signature-tank" component={SignatureTank} />
          <Route path="/slouchy-long-tee" component={SlouchyLongTee} />
          <Route path="/baroque-shirt-black" component={BaroqueShirtBlack} />
          <Route path="/baroque-shirt-white" component={BaroqueShirtWhite} />
          <Route path="/chunky-rib-knit-black" component={ChunkyRibKnitBlack} />
          <Route path="/chunky-rib-knit-red" component={ChunkyRibKnitRed} />
          <Route path="/curtain-shirt" component={CurtainShirt} />
          <Route path="/cut-out-rib-knit-black" component={CutOutRibKnitBlack} />
          <Route path="/logo-zip-up-knit" component={LogoZipUpKnit} />
          <Route path="/printed-hoodie" component={PrintedHoodie} />
          <Route path="/printed-tee" component={PrintedTee} />
          <Route path="/signature-tank-black" component={SignatureTankBlack} />
          <Route path="/tripple-belted-high-neck-vest" component={TrippleBeltedHighNeckVest} />

          {  /* ACCESSORIES */}
          <Route path="/leather-bra-tote-bag-black" component={LeatherBraToteBagBlack} />
          <Route path="/leather-bra-tote-bag-red" component={LeatherBraToteBagRed} />
          {/*           <Route path="/denim-bra-tote-bag" component={DenimBraToteBag} />
 */}          <Route path="/stonewashed-bra-tote-bag" component={StonewashedBraToteBag} />
          <Route path="/bra-tote-bag" component={BraToteBag} />

          {  /* SS24 */}
          {  /* JACKETS */}
          <Route path="/cropped-chainlock-biker-jacket" component={CroppedChainLockBikerJacket} />
          <Route path="/cropped-chainlock-mini-jacket" component={CroppedChainLockMiniJacket} />
          <Route path="/leather-lapel-jacket" component={LeatherLapelJacket} />
          <Route path="/summer-biker-jacket" component={SummerBikerJacket} />
          <Route path="/warrior-jacket" component={WarriorJacket} />

          {  /* PANTS */}
          <Route path="/creased-leather-pants" component={CreasedLeatherPants} />
          <Route path="/creased-pants" component={CreasedPants} />
          <Route path="/folded-waist-skirt" component={FoldedWaistSkirt} />
          <Route path="/over-knee-leather-shorts" component={OverKneeLeatherShorts} />
          <Route path="/over-knee-shorts" component={OverKneeShorts} />
          <Route path="/pleated-volume-pants" component={PleatedVolumePants} />

          {  /* TOPS */}
          <Route path="/chainlock-vest" component={ChainlockVest} />
          <Route path="/creased-layered-muscle-tank" component={CreasedLayeredMuscleTank} />
          <Route path="/creased-mesh-dress" component={CreasedMeshDress} />
          <Route path="/creased-zip-hoodie" component={CreasedZipHoodie} />
          <Route path="/distressed-cropped-hoodie" component={DistressedCroppedHoodie} />
          <Route path="/drape-tank-lightbrown" component={DrapeTankLightbrown} />
          <Route path="/drape-tank-lightgrey" component={DrapeTankLightgrey} />
          <Route path="/drape-tank-white" component={DrapeTankWhite} />
          <Route path="/folded-waist-dress" component={FoldedWaistDress} />
          <Route path="/mesh-layered-tee" component={MeshLayeredTee} />
          <Route path="/slouchy-long-tee-ss24" component={SlouchyLongTeeSS24} />
          <Route path="/twin-fold-tee-grey" component={TwinFoldTeeGrey} />
          <Route path="/twin-fold-tee-white" component={TwinFoldTeeWhite} />


          {  /* ACCESSORIES */}
          <Route path="/mini-bra-denim-tote-bag" component={MiniBraDenimToteBag} />
          <Route path="/mini-bra-tote-bag" component={MiniBraToteBag} />

          {  /* OTHER */}

          <Route path="*" component={PageNotFound} />
          {/*             <Redirect from="/eshop/top/*" to="/eshop" />
 */}
          {/*   <Route path="*" component={Eshop} />
            <Redirect to="/" /> */}

        </Switch>

      </Router>
    </div>
  );
}


export default App;

